/*eslint-disable*/ 
import React from "react";
import Layout from 'components/layout'
import Seo from 'components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { makeStyles } from "@material-ui/core/styles"
import { Link } from 'gatsby-theme-material-ui'

// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import Typography from '@material-ui/core/Typography'

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import Favorite from "@material-ui/icons/Favorite";

// kit
// core components
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Badge from "components/Badge/Badge.js";
import Button from "components/CustomButtons/Button.js";

import Primary from "components/Typography/Primary.js";
import Brown from "components/Typography/Brown.js";

import shingenStyle from "style/kit_shingenStyle.js";

// img
import titleImage from "images/tit2.png";
import logo_l from "images/logo_l.svg"

const useStyles2 = makeStyles((theme) => ({
  section:{
    '& ul':{
      marginBottom: '0!important',
    },
    
    [theme.breakpoints.down('md')]: {
      '& > div > div':{
        
      },
    },
  }
}))
const useStyles = makeStyles(shingenStyle);

const CompanyIndex = ({  }) => {
  const classes = useStyles();
  const cl = useStyles2();
  const siteTitle = "その他の事業"
  const pageTitle = "その他の事業"
  const siteDescription = "申源では、お客様に満足いただけるよう全力でサポートいたします。 "
  
  return (
    <Layout active="3">
      <Seo title={siteTitle} description={siteDescription}/>
      <div>
        <Parallax image={require("images/header_back6.jpg").default} filter="dark" 
          className={classes.parallax}
        >
          <div className={classes.container}>
            <GridContainer justifyContent="center">
              <GridItem xs={12} className={classes.textCenter} >
                <h1 className={classes.title_2} id="logo_l">
                  <img src={logo_l} alert="有限会社 申源"/>
                </h1>
                <h2 className={classes.h2_subtitle}>{ pageTitle }</h2>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classes.main}>
          <div className={classes.container}>
            <Primary className={classes.description}>
              {siteDescription}
            </Primary>
            <div className={classes.section + " " + cl.section}>
              <GridContainer>
                <GridItem md={12} lg={4}>
                  <Card className={classes.card_primary}>
                    <CardBody>
                      <h4>各種保険</h4>
                      <div className={classes.h4_under}>
                        <div className={classes.center_box}>
                          <ul>
                            <li>火災保険</li>
                            <li>地震保険</li>
                            <li>自動車保険</li>
                          </ul>
                        </div>
                        <p>
                          など、各種保険の手続きやご相談もお受けいたします。 <br />
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={12} lg={4}>
                  <Card className={classes.card_primary}>
                    <CardBody>
                      <h4>空家管理</h4>
                      <div className={classes.h4_under}>
                        <p>
                          空き家の悩み・トラブルを<br />「<Link to href="https://akiya.shingen.info/" target="_blank">空き家あんしん巡回サポーター</Link>」が解決します！ <br />
                        </p>
                      </div>
                      <StaticImage
                        src="../images/t4_ba1.png"
                        layout="fullWidth"
                        placeholder="blurred"
                        alt=""
                      />
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem md={12} lg={4}>
                  <Card className={classes.card_primary}>
                    <CardBody>
                      <h4>リノベーション請負 </h4>
                      <div className={classes.h4_under}>
                        <p>
                          古いアパートを再生しませんか？ <br />
                        </p>
                        <StaticImage
                          src="../images/t4_ba2.png"
                          layout="fullWidth"
                          placeholder="blurred"
                          alt=""
                        />
                      </div>
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CompanyIndex

